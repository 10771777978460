import React from 'react';
import MainButton from "../../mainButton";
import Link from "next/link";

const HowTo = ({t}) => {

    const how_to = t('common:how_to', {}, {returnObjects: true})

    const Item = ({item, i}) => {
        return <li
            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
            data-aos-delay={100 * (i + 1)}
            className='list_item'>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
        </li>
    }

    return (
        <section className='how_to_section' id={'how'}>
            <div className="container">
                <div className="how_to_wrapper">
                    <div className='how_to_title'
                         data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
                         data-aos-delay={100}>
                        <h2
                            data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={200}>{how_to?.title}</h2>
                        <p
                            data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={300}>{how_to?.sub}</p>
                        <div
                            data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={400}
                        >
                            <Link
                                prefetch={false}
                                href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}
                            >
                                <MainButton
                                    text={how_to?.button}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='how_to_list'>
                        <ul className='list'>
                            {
                                how_to?.items.map((item, i) => {
                                    return <Item
                                        i={i}
                                        key={item.key}
                                        item={item}
                                    />
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowTo;