import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Keyboard, Navigation, Scrollbar} from "swiper";

const Roadmap = ({t}) => {

    const roadmap = t('common:roadmap', {}, {returnObjects: true})

    return (
        <section className='roadmap_section' id={'roadmap'}>
            <div className='container'>
                <h2
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                    data-aos-delay={0}
                    className='roadmap_section_title'>{roadmap.title}</h2>
                <p
                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                    data-aos-delay={100}
                    className='roadmap_section_subtitle'>{roadmap.sub}</p>
            </div>
            <div className='roadmap_slider_wrapper'>
                <div className='container'>
                    <div
                        data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={200}
                        className='roadmap_slider'>
                        <Swiper
                            keyboard={{
                                enabled: true,
                                onlyInViewport: true
                            }}
                            centeredSlides={false}
                            grabCursor={true}
                            cssMode={true}
                            spaceBetween={2}
                            mousewheel={true}
                            autoplay={true}
                            navigation={{clickable: true}}
                            breakpoints={{
                                375: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 2,
                                },
                                767: {
                                    slidesPerView: 3,
                                },
                                992: {
                                    slidesPerView: 4,
                                },
                                1200: {
                                    slidesPerView: 6,
                                }
                            }}
                            lazy={false}
                            modules={[Navigation, Scrollbar, Keyboard]}
                            slidesPerView={'auto'}
                        >
                            {roadmap.list.map(slide => {
                                return (
                                    <SwiperSlide key={slide.title}>
                                        <div className='roadmap_slider_slide'>
                                            <img
                                                src={`/img/pages/home/roadmap/${slide.icon}.svg`}
                                                alt={'slide.alt'}
                                                width={38}
                                                height={38}
                                                className='slide_img'
                                            />
                                            <div className={`circle ${slide.status}`}/>
                                            <div className={`slide_info ${slide.status}`}>
                                                <h3>{slide.title}</h3>
                                                {slide.content.map(el => {
                                                    return <p key={el} className='slide_info_text'>{el}</p>
                                                })}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Roadmap;