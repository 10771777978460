import React, {useState, useEffect} from 'react';
import DoughnutChart from './DoughnutChart'

const Tokenomics = ({t}) => {

    const tokenomics = t('common:tokenomics', {}, {returnObjects: true})
    const chart = tokenomics.chart

    const [enabled, setEnabled] = useState(true);

    const setEn = () => {
        if (window.innerWidth > 576) {
            setEnabled(true)
        } else {
            setEnabled(false)
        }
    }

    useEffect(() => {

        setEn()

        window.addEventListener('resize', setEn)

        return () => {
            window.removeEventListener('resize', setEn)
        }
    }, [])

    return (
        <section className='tokenomics_section' id="tokenomics">
            <div className="container">
                <h2
                     data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={200} className='tokenomics_section_title'>{tokenomics.title}</h2>
                <p
                     data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={200} className='tokenomics_section_sub'>{tokenomics.sub}</p>
                <div className="tokenomics_section_wrapper">
                    <div
                        data-aos="fade-right" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={200}
                        className="chart">
                        <div className="inner">
                            <picture>
                                <img width={200} height={200} src='/img/main/pixel_coin.svg' alt={'pixel coin shiba budz'}/>
                            </picture>
                        </div>
                        <DoughnutChart
                            data={chart}
                        />
                    </div>
                    <div
                        data-aos="fade-left" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={200}
                        className='metrics'>
                        {tokenomics.metrics.map(el => {
                            return <div className='metrics_item' key={el.title}>
                                <h3 className='metrics_item_title'>{el.title}</h3>
                                <div style={{backgroundColor: el.add_bg}} className='progress_container'>
                                    <span style={{width: `${el.value}%`, backgroundColor: el.main_bg}} className='progress_value'>{el.value}%</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tokenomics;