import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css';
import "swiper/css/scrollbar";
import {Navigation, Pagination, Scrollbar, Keyboard} from "swiper";
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const About = ({t}) => {

    const about = t('common:about', {}, {returnObjects: true})

    return (
        <section className='about_section'  id={'about'}>
            <div className="container">
                <div className="about_section_wrapper">
                    <div className='about_section_image'>
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/about/about_small.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/about/about.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/about/about_small.png`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/about/about.png`}
                            />
                            <img
                                data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={300}
                                src={`/img/pages/home/about/about.png`}
                                alt={'shiba budz nft'}
                                width={350}
                                height={350}
                                className=''
                            />
                        </picture>
                    </div>
                    <div className='about_section_title'>
                        <h2
                            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={100}
                            className='about_section_title_title'>
                            {about.title}
                        </h2>
                        <p data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={200}
                            className='about_section_title_description'>{about.desc_1}</p>
                        <p data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={300}
                            className='about_section_title_description'>{about.desc_2}</p>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default About;