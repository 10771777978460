import React from 'react';
import MainButton from "../../mainButton";
import Link from "next/link";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Lazy} from "swiper";

const GamingWallet = ({t}) => {

    const gaming_wallet = t('common:gaming_wallet', {}, {returnObjects: true})

    const images = [
        'splash',
        'coins',
        'home',
        'swap',
        'payment'
    ]

    return (
        <section className='gaming_wallet_section'>
            <div className="container">
                <div className="gaming_wallet_section_wrapper">
                    <div className='gaming_wallet_info'>
                        <h2
                            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={200}
                            className='gaming_wallet_info_title'>{gaming_wallet.title}</h2>
                        <p
                            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={300}
                            className='gaming_wallet_info_description'>{gaming_wallet.desc_1}</p>
                        <p
                            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={300}
                            className='gaming_wallet_info_description'>{gaming_wallet.desc_2}</p>
                        <div
                            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={300}
                            className='gaming_wallet_info_buttons'>
                            <Link
                                prefetch={false}
                                href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}
                            >
                                <MainButton
                                    text={gaming_wallet.btn_1}
                                />
                            </Link>
                            <Link
                                prefetch={false}
                                href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}
                            >
                                <MainButton
                                    text={gaming_wallet.btn_2}
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='gaming_wallet_slider'>
                        <Swiper
                            centeredSlides={false}
                            cssMode={true}
                            spaceBetween={20}
                            loop={true}
                            autoplay={{
                                disableOnInteraction: false,
                                speed: 1000
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 1,
                                }
                            }}
                            lazy={false}
                            modules={[Autoplay, Lazy]}
                            slidesPerView={'auto'}
                        >
                            {images.map((slide, i) => {
                                return (
                                    <SwiperSlide key={slide + i} data-swiper-autoplay={5000}>
                                        <div className='partners_slider_slide'>
                                            <picture>
                                                <source
                                                    srcSet={`/img/pages/home/gaming_wallet/${slide}.webp`}
                                                    type="image/webp"
                                                />

                                                <source
                                                    srcSet={`/img/pages/home/gaming_wallet/${slide}.png`}
                                                />

                                                <img
                                                    src={`/img/pages/home/partners/${slide}.png`}
                                                    alt={'shiba budz partner'}
                                                    width={428}
                                                    height={926}
                                                    className=''
                                                />
                                            </picture>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default GamingWallet;