import React from 'react';

const EDF = ({t}) => {

    const edf = t('common:edf', {}, {returnObjects: true})

    const Item = ({item, i}) => {
        return <li
            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
            data-aos-delay={50 * (i +1)}
            className='edf_section_info_items_item'>
            <img width={50} height={50} src={`/img/pages/home/edf/${item.icon}.svg`} alt={item.icon}/>
            {item.title}
        </li>
    }

    return (
        <section className='edf_section'>
            <div className="container">
                <div className='edf_section_wrapper'>
                    <div className='edf_section_info'>
                        <h2
                              data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={0}
                            className='edf_section_info_title'>{edf.title}</h2>
                        <p
                              data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                        data-aos-delay={100}
                            className='edf_section_info_description'>{edf.sub}</p>

                    </div>
                    <div className='edf_section_scheme'>
                        <picture>
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/edf/investment_network.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/edf/investment_network.webp`}
                                type="image/webp"
                            />
                            <source
                                media="(max-width: 768px)"
                                srcSet={`/img/pages/home/edf/investment_network.png`}
                            />
                            <source
                                media="(min-width: 769px)"
                                srcSet={`/img/pages/home/edf/investment_network.png`}
                            />
                            <img
                                data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={200}
                                src={`/img/pages/home/edf/investment_network.png`}
                                alt={'shiba budz nft'}
                                width={554}
                                height={437}
                                className=''
                            />
                        </picture>
                    </div>
                    <ul className="edf_section_info_items">
                        {edf.items.map((item, i) => {
                            return <Item
                                i={i}
                                key={item.icon}
                                item={item}
                            />
                        })}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default EDF;