import React from 'react';

const ProgressLine = ({pct}) => {

    return (
        <div className='progress_line_wrapper'>
            <div className='progress'>
                <div data-percent={`${pct?.toFixed(2)}%`} style={{width: `${pct}%`}} className="progress_bar"/>
            </div>
        </div>
    );
};

export default ProgressLine;