import React from 'react';

const Team = ({t}) => {

    const team = t('common:team', {}, {returnObjects: true})

    const Item = ({item, i}) => {
        return <li
            data-aos="fade-up" data-aos-duration="1200" data-aos-easing="ease-out-quad"
            data-aos-delay={100 * (i + 2)}
            className='team_item'>
            <picture>
                <source
                    media="(max-width: 768px)"
                    srcSet={`/img/pages/home/team/${item.img}_small.webp`}
                    type="image/webp"
                />
                <source
                    media="(min-width: 769px)"
                    srcSet={`/img/pages/home/team/${item.img}.webp`}
                    type="image/webp"
                />
                <source
                    media="(max-width: 768px)"
                    srcSet={`/img/pages/home/team/${item.img}_small.png`}
                />
                <source
                    media="(min-width: 769px)"
                    srcSet={`/img/pages/home/team/${item.img}.png`}
                />
                <img
                    src={`/img/pages/home/team/${item.img}.png`}
                    alt={'shiba budz team'}
                    width={200}
                    height={200}
                    className='team_item_image'
                />
            </picture>
            <h3 className='team_item_title'>{item.name}</h3>
            <p className='team_item_position'>{item.position}</p>
            <p className='team_item_desc'>{item.desc}</p>
        </li>
    }

    return (
        <section className='team_section' id={'team'}>
            <div className="container">
                <div className="team_section_wrapper">
                    <h2
                        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={100}
                        className='team_section_title'>{team.title}</h2>
                    <p
                        data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                                data-aos-delay={200}
                        className='team_section_subtitle'>{team.sub}</p>
                    <ul className='team_section_team'>
                        {team.team.map((item, i) => {
                            return <Item
                                i={i}
                                key={item.position}
                                item={item}
                            />
                        })}
                    </ul>

                </div>
            </div>
        </section>
    );
};

export default Team;