
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useEffect, useState } from 'react';
import MainLayout from "../layouts/main";
import useTranslation from "next-translate/useTranslation";
import Header from "../components/header";
import Banner from "../components/mainPage/Banner";
import About from "../components/mainPage/About";
import Tokenomics from "../components/mainPage/Tokenomics";
import Roadmap from "../components/mainPage/Roadmap";
import Team from "../components/mainPage/Team";
import Faqs from "../components/mainPage/FAQ";
import Footer from "../components/footer";
import Loader from "../components/Loader";
import Partners from "../components/mainPage/Partners";
import HowTo from "../components/mainPage/HowTo";
import GamingWallet from "../components/mainPage/GamingWallet";
import EDF from "../components/mainPage/EDF";
import { useRouter } from "next/router";
import Link from "next/link";
//push
export default function Home() {
    const router = useRouter();
    const { t } = useTranslation('common');
    const seo = t('common:seo', {}, { returnObjects: true });
    const [isLoading, setIsLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [apiData, setApiData] = useState(undefined);
    const getData = async () => {
        const res = await fetch('https://api.shibabudz.org/v1/statistic');
        return await res.json();
    };
    useEffect(() => {
        let appLoading = new Promise((resolve, reject) => {
            setTimeout(resolve, 2000, true);
        });
        let apiData = getData().then(data => {
            setApiData(data);
        }).catch(() => {
            setApiData({
                tokens_sold_by_stage: 0,
                tokens_sold: 0,
                token_price: 0.005,
                stage: {
                    distribution: 0,
                    stage_name: "Stage"
                }
            });
        });
        Promise.all([appLoading, apiData]).then(values => {
            setIsLoading(false);
            setLoaded(true);
        });
    }, []);
    return (<MainLayout loaded={loaded} title={seo.index_page.title} description={seo.index_page.description} canonical={router.locale === 'en' ? `https://shibabudz.org` : `https://shibabudz.org/${router.locale}`} alternate={''}>
            <div className={'top_line'}>
                <div className="container">
                    <div className="top_line_content">
                        Claim your tokens on the 13th May 2024 - <Link href={'https://claim.shibabudz.org/'}>Click Here</Link>
                    </div>
                </div>
            </div>
            <Header t={t} loaded={loaded}/>
            <main className='main'>
                <Banner t={t} apiData={apiData}/>
                <Partners t={t}/>
                <About t={t}/>
                <HowTo t={t}/>
                <Tokenomics t={t}/>
                <GamingWallet t={t}/>
                <EDF t={t}/>
                <Roadmap t={t}/>
                <Team t={t}/>
                <Faqs t={t}/>
            </main>
            <Footer full={true} t={t}/>
            <Loader isLoading={isLoading} loaded={loaded}/>
        </MainLayout>);
}

    async function __Next_Translate__getStaticProps__18f74fd342d__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18f74fd342d__ as getStaticProps }
  