import React, {useEffect, useState} from "react";
import ProgressLine from "../../progress";
import {numberWithCommas, numberWithCommasNull} from "../../../helpers/numberWithCommas";
import MainButton from "../../mainButton";
import Link from "next/link";

const Progress = (props) => {

    const {apiData, t} = props
    const progress = t('common:progress', {}, {returnObjects: true})
    const live = t('common:live', {}, {returnObjects: true})
    const [complete, setComplete] = useState(0);
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setComplete(completed)
        }, 1000)
    }, [completed])

    useEffect(() => {
        if (apiData?.tokens_sold_by_stage && apiData?.tokens_sold_by_stage !== 0) {
            setCompleted(((apiData?.tokens_sold_by_stage * 100) / apiData?.stage?.distribution) || 0)
        } else {
            setCompleted(0)
        }
    }, [apiData])

    return (
        <div className='progress_section'
             data-aos="fade-in" data-aos-duration="800" data-aos-easing="ease-out-quad"
             data-aos-delay={300}
        >
            <picture>
                <source
                    media="(min-width: 769px)"
                    srcSet={`/img/pages/home/banner/banner_top.webp`}
                    type="image/webp"
                />
                <source
                    media="(min-width: 769px)"
                    srcSet={`/img/pages/home/banner/banner_top.png`}
                />
                <img
                    src={`/img/pages/home/banner/banner_top.png`}
                    alt={'shiba budz'}
                    width={84}
                    height={85}
                    className='top_shiba'
                />
            </picture>
            <div className='title_data'

                 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                 data-aos-delay={500}>
                <h2 className='title_data_title'>
                    <span>{live.title_1}</span>
                    <span>{live.title_2}</span>
                </h2>
                <h3 className='title_data_sub'>
                    <span>{live.sub} - </span>
                    <span>0xC955faa911D7507aEE0c0d7a2d5a79c79a041AB8</span>
                </h3>
            </div>
            <div className='main_data'
                 data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                 data-aos-delay={700}
            >
                <div className='stage'>
                    <h2 className='stage_name '>{progress.progress_part.stage} {apiData?.stage?.stage_name?.split(' ')[1]}</h2>
                    <h3 className='stage_status '>{progress.progress_part.live}</h3>
                </div>
                <div id='value' className='raised'>
                    <span className='raised_amount'>{numberWithCommasNull(Number(apiData?.tokens_sold_by_stage || 0))}
                        </span>
                    <span className='total'>/ {numberWithCommasNull(Number(apiData?.stage?.distribution || 0))} <img
                        width={31}
                        height={27}
                        alt={'coins icon'}
                        src={'/img/pages/home/banner/progress_coins.svg'}/></span>
                </div>
            </div>
            <ProgressLine
                pct={complete || 0}
            />
            <div className='additional_data'>
                <div className='additional_data_item price'>
                    <span className='additional_data_item_value'>{apiData?.token_price} USDT = 1 $BUDZ
                    <img width={32} height={32} src='/img/main/pixel_coin.svg' alt={'pixel coin shiba budz'}/>
                    </span>
                </div>
                <div className='raised_usdt'>
                    <span className='raised_usdt_title'>USDT Raised:
                        </span>
                    <span className='raised_usdt_amount'>{numberWithCommas(Number(apiData?.raised_usd || 0))}
                        </span>
                </div>
            </div>
            <div className='cta_data'>
                <Link
                    prefetch={false}
                    href={process.env.NEXT_PUBLIC_BUY_TOKEN_APP_URL}
                >
                    <MainButton
                        text={progress.button}
                    />
                </Link>
            </div>

        </div>
    );
};

export default Progress;