import React, {useEffect, useState} from "react";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


const DoughnutChart = ({data}) => {

    const [isMob, setIsMob] = useState(false)
    const [options, setOptions] = useState(undefined)
    const [chart, setChart] = useState(undefined)

    useEffect(() => {
        if (window.innerWidth < 576) {
            setIsMob(true)
        }
    }, [])

    useEffect(() => {
        if (data) {

            setOptions(
                {
                    responsive: true, maintainAspectRatio: false,
                    cutout: isMob ? '77%' : '75%',
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            formatter: (value) => {
                                return value + '%';
                            },
                            color: (ctx) => ctx.dataset.backgroundColor,

                            font: {
                                size: isMob ? 14 : 16,
                                family: 'Poppins-500, san-serif',
                            },
                            padding: '-2 6',
                            align: isMob ? 'end' : 'end',
                            anchor: isMob ? 'end' : 'end',
                        },
                    }
                }
            )
            setChart(data)

        }
    }, [data])

    return (
        chart ? <Doughnut data={chart} options={options} plugins={[ChartDataLabels]}/> : <span>Loading...</span>
    );
};

DoughnutChart.defaultProps = {
    height: 350,
    width: 400,
    innerRadius: 55,
    radius: 75,
    nameKey: "name",
    valueKey: "y"
};

export default DoughnutChart;
