import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Scrollbar, Autoplay, FreeMode} from "swiper";

const Partners = ({t}) => {

    const partners = t('common:partners', {}, {returnObjects: true})

    return (
        <section className='partners_section'>
            <div className="container">
                <div className="partners_section_wrapper">
                    <div className='partners_slider'>
                        <Swiper
                            centeredSlides={false}
                            cssMode={true}
                            spaceBetween={20}
                            loop={true}
                            autoplay={{
                                disableOnInteraction: false,
                                speed: 1000
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 2,
                                },
                                576: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 3,
                                },
                                992: {
                                    slidesPerView: 5,
                                }
                            }}
                            lazy={false}
                            modules={[Autoplay]}
                            // slidesPerView={5}
                            slidesPerView={"auto"}
                        >
                            {partners.map(slide => {
                                return (
                                    <SwiperSlide key={slide.title} data-swiper-autoplay={3000}>
                                        <div className='partners_slider_slide'>
                                            <picture>
                                                <source
                                                    srcSet={`/img/pages/home/partners/${slide.img}.webp`}
                                                    type="image/webp"
                                                />

                                                <source
                                                    srcSet={`/img/pages/home/partners/${slide.img}.png`}
                                                />

                                                <img
                                                    src={`/img/pages/home/partners/${slide.img}.png`}
                                                    alt={'shiba budz partner'}
                                                    width={slide.w}
                                                    height={slide.h}
                                                    className=''
                                                />
                                            </picture>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;