import React from 'react';
import Progress from "./progress";
import 'swiper/css/effect-fade'

const Banner = ({t, apiData}) => {

    const banner = t('common:banner', {}, {returnObjects: true})

    return (
        <section className='banner_section'>
            <div className='container'>
                <div className="banner_section_wrapper">
                    <div className="title_block">
                        <h1
                            data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={100}
                            className='title_block_title'>
                            <span>{banner.title_1}</span>
                            <span>{banner.title_2}</span>
                            <span className=''>{banner.title_3}</span>
                        </h1>
                        <p className='title_block_sub'>{banner.sub_title}</p>
                        <div className='title_block_images'>
                            <picture>
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={`/img/pages/home/banner/banner_1_small.webp`}
                                    type="image/webp"
                                />
                                <source
                                    media="(min-width: 769px)"
                                    srcSet={`/img/pages/home/banner/banner_1.webp`}
                                    type="image/webp"
                                />
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={`/img/pages/home/banner/banner_1_small.png`}
                                />
                                <source
                                    media="(min-width: 769px)"
                                    srcSet={`/img/pages/home/banner/banner_1.png`}
                                />
                                <img
                                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={100}
                                    src={`/img/pages/home/banner/banner_1.png`}
                                    alt={'shiba budz nft'}
                                    width={148}
                                    height={148}
                                    className=''
                                />
                            </picture>
                            <picture>
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={`/img/pages/home/banner/banner_2_small.webp`}
                                    type="image/webp"
                                />
                                <source
                                    media="(min-width: 769px)"
                                    srcSet={`/img/pages/home/banner/banner_2.webp`}
                                    type="image/webp"
                                />
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={`/img/pages/home/banner/banner_2_small.png`}
                                />
                                <source
                                    media="(min-width: 769px)"
                                    srcSet={`/img/pages/home/banner/banner_2.png`}
                                />
                                <img
                                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={200}
                                    src={`/img/pages/home/banner/banner_2.png`}
                                    alt={'shiba budz nft'}
                                    width={148}
                                    height={148}
                                    className=''
                                />
                            </picture>
                            <picture>
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={`/img/pages/home/banner/banner_3_small.webp`}
                                    type="image/webp"
                                />
                                <source
                                    media="(min-width: 769px)"
                                    srcSet={`/img/pages/home/banner/banner_3.webp`}
                                    type="image/webp"
                                />
                                <source
                                    media="(max-width: 768px)"
                                    srcSet={`/img/pages/home/banner/banner_3_small.png`}
                                />
                                <source
                                    media="(min-width: 769px)"
                                    srcSet={`/img/pages/home/banner/banner_3.png`}
                                />
                                <img
                                    data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-out-quad"
                            data-aos-delay={300}
                                    src={`/img/pages/home/banner/banner_3.png`}
                                    alt={'shiba budz nft'}
                                    width={148}
                                    height={148}
                                    className=''
                                />
                            </picture>
                        </div>
                    </div>
                    <div className="progress_block">
                        <Progress
                            t={t}
                            apiData={apiData}
                        />
                    </div>
                </div>

            </div>
        </section>
    );
};

export default Banner;