import React from 'react';
import Accordion from "../../accordion";

const Faqs = ({t}) => {

    const faqs = t('common:faqs', {}, {returnObjects: true});

    return (
        <section className='faqs_section' id='faq'>
            <div className="container">
                <div className="faqs_section_wrapper">
                    <div className='faqs_section_bg'>
                    <h2
                        className="faqs_section_title"
                    >
                        {faqs.title}
                    </h2>
                    <p className='faqs_section_subtitle'>{faqs.sub}</p>
                    <Accordion
                        faqs_list={faqs.data}
                    />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faqs;